<template>
    <div class="container_con">
        <el-card>
            <div class="con_title"><span></span> 产品详情</div>
            <div class="flex" v-if="form" style="align-items: flex-start;">
                <div class="items flex flex_wrap">
                    <div class="flex_ac item">
                        <div class="tt">产品编号:</div>
                        <div class="flex1">{{form.product_no}}</div>
                    </div>
                    <div class="flex item">
                        <div class="tt">品名:</div>
                        <div class="flex1">
                            {{form.product_name}}
                        </div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">规格:</div>
                        <div class="flex1">{{form.spec_value_name}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">型号:</div>
                        <div class="flex1">{{form.model}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">产品注册证名称:</div>
                        <div class="flex1">{{form.register_cert_name}}</div>
                    </div>
                    <div class="flex item">
                        <div class="tt">注册证号:</div>
                        <div class="flex1">
                            {{form.register_cert_no}}
                        </div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">包装单位:</div>
                        <div class="flex1">{{form.pack_unit_name}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">申请原因:</div>
                        <div class="flex1">{{form.apply_reason}}</div>
                    </div>
                </div>

                <div class="items flex flex_wrap">
                    <div class="flex_ac item">
                        <div class="tt">生产厂家:</div>
                        <div class="flex1">{{form.manufacturer}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">助记码:</div>
                        <div class="flex1">{{form.mnemonic_code}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">类别:</div>
                        <div class="flex1">{{form.type_name}}</div>
                    </div>
                    
                    <!-- <div class="flex_ac item">
                        <div class="tt">产品分类:</div>
                        <div class="flex1">{{form.category_name}}</div>
                    </div> -->
                    <div class="flex_ac item">
                        <div class="tt">储存条件:</div>
                        <div class="flex1">{{form.storage_condition_name}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">进价:</div>
                        <div class="flex1">{{form.purchase_price}} 元</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">注册证到期时间:</div>
                        <div class="flex1">{{form.register_cert_expire || '长效期'}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">零售价:</div>
                        <div class="flex1">{{form.price}} 元</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">备注:</div>
                        <div class="flex1">{{form.remark}}</div>
                    </div>
                </div>
            </div>
            
            <div class="con_title" style="margin-top: 15px;"><span></span> 审批信息</div>
            <div class="" v-if="form" style="align-items: flex-start;">
                <div class="items flex" style="width: 100%;"  v-for="(item,index) in form.product_first_audit" :key="index">
                    <div class="flex_ac item"  v-if="sp[index]">
                        <div class="tt">{{sp[index].title}}审核:</div>
                        <div class="flex1">{{item.full_name}}---{{item.opinion==1?'同意':'不同意'}}({{item.audit_date}})</div>
                    </div>
                </div>
            </div>    
            <div class="bottom_btn" style="justify-content: flex-start;">
                <el-button @click="$router.go(-1)">返回</el-button>
            </div>

        </el-card>
        <!-- 上传图片预览 -->
        <el-dialog :close-on-click-modal="false" title="图片预览" width="600px" v-model="uploadImgVisible">
            <img style="width: 100%;" :src="uploadImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
    // 上传组件
    export default {
        name: "productDetail",
        data() {
            return {
                form: '',
                imgs: [],

                // 上传图片
                uploadImageUrl: '',
                uploadImgVisible: false,
                
                sp:[
                    {title:'业务部'},
                    {title:'质管部'},
                    {title:'销售部'},
                    {title:'总经理'}
                ]
            };
        },
        created() {
            this.id = this.$route.query.id;
            this.get_data()
        },
        methods: {
            PicturePreview(file) {
                this.uploadImageUrl = file.url;
                this.uploadImgVisible = true;
            },
            get_data() {
                this.$httpGet("/backend/product.ProductFirst/read", {
                    product_first_id: this.id
                }).then((res) => {
                    if (res.status == 200) {
                        this.form = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .items {
        width: 50%;
        padding-right: 30px;
    }

    .items .item {
        padding: 10px;
        width: 100%;
        padding-right: 30px;
        line-height: 24px;
        color: #333;
        font-size: 14px;
    }

    .items .item .tt {
        color: #888;
        width: 120px
    }

    .avatar {
        height: 80px;
    }
</style>
